import React, { useRef, useState, useEffect } from "react";
import { setStorage, getStorage, deleteStorage } from "../modals/Storage";
import {
  API_ACCESS_URL,
  AVATARS_ARR,
  getRandomInt,
  openNewPage,
} from "../modals/Constants";
import { Link, useNavigate } from "react-router-dom";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import NoticeDialog from "../dialogs/NoticeDialog2";
import SlideShow from "../other-components/SlideShow";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import ImportantNotice from "../other-components/ImportantNotice/Notice";
import "../../MainStyle.css";
import ToastDialog from "../dialogs/ToastDialog/toast";

function Home() {
  const navigate = useNavigate();
  const [isLoadingShow, setLoadingShow] = useState(false);
  const [viewBoxNumber, setViewBoxNumber] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const tabsContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [pageConst, setConstants] = useState({
    accountId: "",
    accountBalance: 0,
    isDataLoaded: false,
    recordList: [],
    sliderImages: [],
    winnersRecordList: [],
  });

  const [toastDialogConst, setToastDialogConst] = useState({
    toastDialogShow: false,
    toastDialogMode: 1,
    toastMessage: "",
  });
  const updateToastDialogState = (data, mode, msg) => {
    // mode: 1 / success
    // mode: 2 / warning

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogMode: mode };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };
    const handleImageClick = async (gameId) => {
        var userId = getStorage("uid");
        updateToastDialogState(true, 2, "Data Created Successfully! \n Redirecting You!");
        try {
            const response = await fetch('https://api.dreamclub.link/JiliGame/enter3rdParty.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    gameid: gameId,
                    userid: userId,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.error) {
                console.error('Error:', data.error);
                // Display an error message to the user
                alert(`Error: ${data.error}`);
            } else if (data.login_url) {
                // Navigate to the JiliGamesController route with the login URL
                navigate(`/jili-games?loginUrl=${encodeURIComponent(data.login_url)}`);
            } else {
              updateToastDialogState(true, 2, "505 Error \n Format Invalid!");

            }
        } catch (error) {
          updateToastDialogState(true, 2, "505 \n Invalid Response!");
        }
    };

const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - tabsContainerRef.current.offsetLeft);
    setScrollLeft(tabsContainerRef.current.scrollLeft);
  };

  const handleMouseLeaveOrUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - tabsContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    tabsContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - tabsContainerRef.current.offsetLeft);
    setScrollLeft(tabsContainerRef.current.scrollLeft);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - tabsContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    tabsContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  

  const [noticeDialogConst, setNoticeDialogConst] = useState({
    noticeTitle: "",
    noticeNote: "",
    noticeDialogShow: false,
  });

  const refreshData = () => {
    setConstants((previousState) => {
      return { ...previousState, isDataLoaded: false };
    });
  };

  const updateSliderImagesArr = (data) => {
    if (data.length > 0) {
      setConstants((previousState) => {
        return { ...previousState, sliderImages: data };
      });
    }
  };

  const showNotice = (data) => {
    if (data.length > 0) {
      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeTitle: data[0]["notice_title"] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeNote: data[0]["notice_note"] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeDialogShow: true };
      });
    }
  };

  const updateNoticeDialogState = (data) => {
    setNoticeDialogConst((previousState) => {
      return { ...previousState, noticeDialogShow: data };
    });
  };

  function getAccountInfo(accountId, accountAuthSecret) {
    const fecthApiData = async (url, authSecret) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-account-info",
            AuthToken: authSecret,
          },
        });
        const data = await res.json();

        setLoadingShow(false);

        if (data.status_code == "success") {
          let accountAvatarId = data.data[0]["account_avatar_id"];
          let accountUserName = data.data[0]["account_user_name"];
          let accountBalance = data.data[0]["account_balance"];
          let accountWinningBalance = data.data[0]["account_w_balance"];
          let accountCommissionBalance = data.data[0]["account_c_balance"];
          let accountLevel = data.data[0]["account_level"];
          let accountLastActive = data.data[0]["account_last_active"];

          let service_app_status = data.data[0]["service_app_status"];
          let service_min_recharge = data.data[0]["service_min_recharge"];
          let service_min_withdraw = data.data[0]["service_min_withdraw"];
          let service_recharge_option = data.data[0]["service_recharge_option"];
          let service_telegram_url = data.data[0]["service_telegram_url"];
          let service_payment_url = data.data[0]["service_payment_url"];
          let service_livechat_url = data.data[0]["service_livechat_url"];
          let service_imp_message = data.data[0]["service_imp_message"];
          let service_app_download_url =
            data.data[0]["service_app_download_url"];

          setStorage("avatarId", accountAvatarId);
          setStorage("uname", accountUserName);
          setStorage("balance", accountBalance);
          setStorage("cbalance", accountCommissionBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("accountLevel", accountLevel);
          setStorage("accountLastActive", accountLastActive);
          setStorage("minwithdraw", service_min_withdraw);
          setStorage("minrecharge", service_min_recharge);
          setStorage("rechargeoptions", service_recharge_option);
          setStorage("telegramURL", service_telegram_url);
          setStorage("liveChatURL", service_livechat_url);
          setStorage("paymentURL", service_payment_url);
          setStorage("impMessage", service_imp_message);
          setStorage("appDownloadURL", service_app_download_url);
          // setStorage("winnersArr", JSON.stringify(data.winnersArr));
          setConstants({
            ...pageConst,
            accountBalance: accountBalance,
            isDataLoaded: true,
          });

          if (service_app_status == "OFF") {
            navigate("/um", { replace: true });
          } else {
            updateWinnersList(data.winnersArr);
            showNotice(data.noticeArr);
            updateSliderImagesArr(data.slideShowList);
          }
        } else if (data.status_code == "authorization_error") {
          if (deleteStorage()) {
            navigate("/login", { replace: true });
          }
        }
      } catch (error) {
        setLoadingShow(false);
      }
    };

    if (!isLoadingShow && !pageConst.isDataLoaded) {
      setLoadingShow(true);
      fecthApiData(API_ACCESS_URL + "?USER_ID=" + accountId, accountAuthSecret);
    }
  }

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random());
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  };

  const updateWinnersList = (data) => {
    let tempData = [];

    tempData.push(
      <div key={61}>
        <div className={[styles.winnersListStageView]}>
          <div className="col-view v-center mg-t--45">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[1]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no2_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no2_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[1]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[1]["winning_amount"]}
            </div>
          </div>

          <div className="col-view v-center mg-t--90">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[0]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no1_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no1_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[0]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[0]["winning_amount"]}
            </div>
          </div>

          <div className="col-view v-center mg-t--45">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[2]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no3_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no3_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[2]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[2]["winning_amount"]}
            </div>
          </div>
        </div>
      </div>
    );

    for (let i = 3; i < data.length; i++) {
      tempData.push(
        <div
          key={i}
          className={[
            general.rowView,
            general.spaceBetween,
            styles.winnerPersonView,
            i > 3 ? general.mgT10px : "",
          ].join(" ")}
        >
          <div className="row-view">
            <span className={[general.fontS20Px, general.width20px].join(" ")}>
              {i + 1}
            </span>

            <div className="h-w-45 ovf-hidden br-50 mg-l-40">
              <img
                src={AVATARS_ARR[Number(data[i]["avatar_id"])]["avatarURL"]}
                className="h-w-45"
                alt="icon"
              />
            </div>
            <span className={[general.fontS16Px, general.mgL10px].join(" ")}>
              {data[i]["mobile_num"]}
            </span>
          </div>
          <span className={[styles.winnerWonAmountView]}>
            ₹{data[i]["winning_amount"]}
          </span>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, winnersRecordList: tempData };
    });
  };

  const openGame = (url) => {
    if (getStorage("uid")) {
      navigate("/" + url, { replace: false });
    } else {
      redirectToLogin();
    }
  };

  const redirectToLogin = () => {
    deleteStorage();
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    let accountId = "";
    let accountAuthSecret = "";

    if (getStorage("uid")) {
      accountId = getStorage("uid");
      accountAuthSecret = getStorage("secret");
    } else {
      accountId = "guest";
      accountAuthSecret = "guest";
    }

    getAccountInfo(accountId, accountAuthSecret);
  }, [pageConst.sliderImages, pageConst.winnersList]);
  

  return (
    <div id="top-start" className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <NoticeDialog
          intentData={noticeDialogConst}
          updateState={updateNoticeDialogState}
        />

        <div className={[styles.homeView, general.posRelative].join(" ")}>
          <div
            className={[
              styles.topBar,
              general.rowView,
              general.spaceBetween,
              general.width100,
              general.appContentsBackground,
            ].join(" ")}
          >
            <img
              className={`h-60-p mg-l-10`}
              src={require("../media/icons/app_logo_fill.png")}
              alt="icon"
            />

            <div className={[!getStorage("uid") ? general.hideView : ""]}>
              <img
                className={`h-25-p`}
                src={require("../media/icons/live_support_icon.png")}
                alt="icon"
                onClick={() => openNewPage(getStorage("liveChatURL"))}
              />
              <img
                className={`h-25-p mg-l-15`}
                src={require("../media/icons/download_icon.png")}
                alt="icon"
                onClick={() => openNewPage(getStorage("appDownloadURL"))}
              />
            </div>

            <div
              className={[
                general.rowView,
                !getStorage("uid") ? "" : general.hideView,
              ].join(" ")}
            >
              <Link
                className={[
                  general.textDecoNone,
                  general.height30px,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS14Px,
                  general.pd5px15px,
                  general.borderRadius,
                  general.bgCustomColor3,
                ].join(" ")}
                to={"/login"}
              >
                Login
              </Link>

              <Link
                className={[
                  general.textDecoNone,
                  general.height30px,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS14Px,
                  general.pd5px15px,
                  general.mgL10px,
                  general.borderRadius,
                  general.bgCustomColor2,
                ].join(" ")}
                to={"/register"}
              >
                Register
              </Link>
            </div>
          </div>

          <div className={[general.pd15px]}>
            <SlideShow dataList={pageConst.sliderImages} />
          </div>

          <div className="pd-0-15">
            <ImportantNotice />
          </div>


          <div className={[styles.homeGameOptionsView]}>
            <div className={[styles.gameOptionsView2]}>
              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgCategory4,
                ].join(" ")}
                onClick={() => setViewBoxNumber(1)}
              >
                <img
                  src={require("../media/icons/backgrounds/11.png")}
                  alt="icon"
                />
                <span>Slots</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgCategory3,
                ].join(" ")}
                onClick={() => setViewBoxNumber(2)}
              >
                <img
                  src={require("../media/icons/backgrounds/9.png")}
                  alt="icon"
                />
                <span>Fishing</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgCategory1,
                  styles.bgCategoryActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(3)}
              >
                <img
                  src={require("../media/icons/backgrounds/12.png")}
                  alt="icon"
                />
                <span>Cards & Tables</span>
              </div>
            </div>
          </div>

  

          <div className={[styles.homeGameOptionsView]}>
            <div className={[styles.gameOptionsView1]}>
              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgCategory1,
                ].join(" ")}
                onClick={() => setViewBoxNumber(1)}
              >
                <img
                  src={require("../media/icons/backgrounds/popularbg.png")}
                  alt="icon"
                />
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgCategory2,
                ].join(" ")}
                onClick={() => setViewBoxNumber(0)}
              >
                <img
                  src={require("../media/icons/backgrounds/lotterybg.png")}
                  alt="icon"
                />
              </div>
            </div>
          </div>
          <div
            className={[
              styles.homeAdditionalGamesTitle,
              viewBoxNumber != 0 ? styles.hideView : "",
            ].join(" ")}
          >
            <span></span>
            Lottery
          </div>

          <div
            className={[
              styles.colView,
              styles.pd15px,
              viewBoxNumber != 0 ? styles.hideView : "",
            ].join(" ")}
          >
            <div
              className="ps-rl txt-deco-n br-10 bg-white"
              onClick={() => openGame("wingo1min")}
            >
              <div className={[styles.homeGameViewBox]}></div>
              <img
                className="h-85-p ps-ab ps-rgt-10 ps-tp--15px"
                src={require("../Games/Wingo/icons/4.png")}
              />
              <div className="col-view ps-ab ps-lf ps-tp pd-15">
                <span className="ft-sz-23 ft-wgt-500 cl-white">Win Go</span>
                <span className="ft-sz-12 cl-white mg-t-10">Guess Number</span>
                <span className="ft-sz-12 cl-white">
                  Green/Red/Purple to win
                </span>
              </div>

              <div className="row-view sb-view pd-10-15 br-10 bg-white">
                <div className="row-view">
                  <div className="h-w-32 ovf-hidden br-50">
                    <img
                      src={AVATARS_ARR[getRandomInt(0, 7)]["avatarURL"]}
                      className="h-w-32"
                      alt="icon"
                    />
                  </div>

                  <span className="ft-sz-13 cl-drk-black mg-l-10">
                    *****{getRandomInt(100, 999)}
                  </span>
                </div>

                <span className="ft-sz-13 cl-drk-black mg-l-10">
                  Won Amount: ₹{getRandomInt(2, 1600).toFixed(2)}
                </span>
              </div>
            </div>

            <div
              className="ps-rl txt-deco-n br-10 mg-t-30 bg-white"
              onClick={() => openGame("k3lottery1min")}
            >
              <div className={[styles.homeGameViewBox]}></div>
              <img
                className="h-85-p ps-ab ps-rgt-10 ps-tp--15px"
                src={require("../icons/dice_lottery_icon.png")}
              />
              <div className="col-view ps-ab ps-lf ps-tp pd-15">
                <span className="ft-sz-23 ft-wgt-500 cl-white">K3 Lottery</span>
                <span className="ft-sz-12 cl-white mg-t-10">Guess Number</span>
                <span className="ft-sz-12 cl-white">Big/Small/Odd/Even</span>
              </div>

              <div className="row-view sb-view pd-10-15 br-10 bg-white">
                <div className="row-view">
                  <div className="h-w-32 ovf-hidden br-50">
                    <img
                      src={AVATARS_ARR[getRandomInt(0, 7)]["avatarURL"]}
                      className="h-w-32"
                      alt="icon"
                    />
                  </div>

                  <span className="ft-sz-13 cl-drk-black mg-l-10">
                    *****{getRandomInt(100, 999)}
                  </span>
                </div>

                <span className="ft-sz-13 cl-drk-black mg-l-10">
                  Won Amount: ₹{getRandomInt(2, 1600).toFixed(2)}
                </span>
              </div>
            </div>

            <div
              className="ps-rl txt-deco-n br-10 mg-t-30 bg-white"
              onClick={() => openGame("AndarBahar")}
            >
              <div className={[styles.homeGameViewBox]}></div>
              <img
                className="h-85-p ps-ab ps-rgt-10 ps-tp--15px"
                src={require("../icons/5dlottery_icon.png")}
              />
              <div className="col-view ps-ab ps-lf ps-tp pd-15">
                <span className="ft-sz-23 ft-wgt-500 cl-white">
                  Andar Bahar
                </span>
                <span className="ft-sz-12 cl-white mg-t-10">Guess Number</span>
                <span className="ft-sz-12 cl-white">
                  Green/Red/Purple to win
                </span>
              </div>

              <div className="row-view sb-view pd-10-15 br-10 bg-white">
                <div className="row-view">
                  <div className="h-w-32 ovf-hidden br-50">
                    <img
                      src={AVATARS_ARR[getRandomInt(0, 7)]["avatarURL"]}
                      className="h-w-32"
                      alt="icon"
                    />
                  </div>

                  <span className="ft-sz-13 cl-drk-black mg-l-10">
                    *****{getRandomInt(100, 999)}
                  </span>
                </div>

                <span className="ft-sz-13 cl-drk-black mg-l-10">
                  Won Amount: ₹{getRandomInt(2, 1600).toFixed(2)}
                </span>
              </div>
            </div>

            <div
              className="ps-rl txt-deco-n br-10 mg-t-30 bg-white"
              onClick={() => openGame("Wheelocity")}
            >
              <div className={[styles.homeGameViewBox]}></div>
              <img
                className="h-85-p ps-ab ps-rgt-10 ps-tp--15px"
                src={require("../icons/trxlottery_icon.png")}
              />
              <div className="col-view ps-ab ps-lf ps-tp pd-15">
                <span className="ft-sz-23 ft-wgt-500 cl-white">Wheelocity</span>
                <span className="ft-sz-12 cl-white mg-t-10">
                  Guess Animal Number
                </span>
                <span className="ft-sz-12 cl-white">
                  Elephant/Tiger/Crown to win
                </span>
              </div>

              <div className="row-view sb-view pd-10-15 br-10 bg-white">
                <div className="row-view">
                  <div className="h-w-32 ovf-hidden br-50">
                    <img
                      src={AVATARS_ARR[getRandomInt(0, 7)]["avatarURL"]}
                      className="h-w-32"
                      alt="icon"
                    />
                  </div>

                  <span className="ft-sz-13 cl-drk-black mg-l-10">
                    *****{getRandomInt(100, 999)}
                  </span>
                </div>

                <span className="ft-sz-13 cl-drk-black mg-l-10">
                  Won Amount: {getRandomInt(2, 1600).toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          <div
            className={[
              styles.homeAdditionalGamesTitle,
              viewBoxNumber != 1 ? styles.hideView : "",
            ].join(" ")}
          >
            <span>  
            </span>
            <div className={[styles.headinggames]}>Slots 
            <div className={[styles.seeallbutton]}>
            {/* <div className={[styles.seealltext]}>See all     
            </div> 
            <svg height="1em" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><path d="M16.88 15.53 7 5.66a1 1 0 0 0-1.41 1.41l9.06 9.06-8.8 8.8a1 1 0 0 0 0 1.41 1 1 0 0 0 1.42 0l9.61-9.61a.85.85 0 0 0 0-1.2Z" fill="#f2ca33" class="fill-231f20"></path><path d="m26.46 15.53-9.88-9.87a1 1 0 0 0-1.41 1.41l9.06 9.06-8.8 8.8a1 1 0 0 0 0 1.41 1 1 0 0 0 1.41 0l9.62-9.61a.85.85 0 0 0 0-1.2Z" fill="#f2ca33" class="fill-231f20"></path></g></svg> */}
            </div>
            </div>
          </div>

          <div
            className={[
              styles.homeAdditionalGamesView,
              viewBoxNumber != 1 ? styles.hideView : "",
            ].join(" ")}
          >


          <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/setting/xCfRmJP2Fm1Fw9VEUHFoCtNaBpfTyXIYdr5Osuzl.png"
                alt="icon"
                onClick={() => handleImageClick(51)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/7hnsYn70DF8p9aAUBr9tPoqMjSDbBxzvNhXdrgle.png"
                alt="icon"
                onClick={() => handleImageClick(109)}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/RubvW163jBzIJIj7Ls8DseMYyS39cNnlbcwLqefX.png"
                alt="icon"
                onClick={() => handleImageClick(146)}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/1nJZS31DrT0NJqDfcfVDR5DGS12TiKniUahJ4efj.png"
                alt="icon"
                onClick={() => handleImageClick(6)}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/2zyjnzyHpfxBBLLSJpK79perLIQQklZHLBJ4tGf1.png"
                alt="icon"
                onClick={() => handleImageClick(38)}
              />
            </div>
            <div>  
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/0NMm5znugdsgOagdTNGinTDLOCWmBljNVyKBuccK.png"
                alt="icon"
                onClick={() => handleImageClick(126)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/hIqrVrY2nxEAKQa7usmdsB5wzDau4xb8sj62Mncs.png"
                alt="icon"
                onClick={() => handleImageClick(130)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/M5LoqW4yYYVmbMs8jrlBWppsFhMDSWqE9yqINrgc.png"
                alt="icon"
                onClick={() => handleImageClick(77)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/HklsQTpCPg3yxbpCUnzrk6tlhmAF4XJ2uYs6G1Ns.png"
                alt="icon"
                onClick={() => handleImageClick(101)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/LGmOw0w4iklkLKXz81LfNjNFjDQw5IlZgRSULqy2.png"
                alt="icon"
                onClick={() => handleImageClick(223)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/VZRGHowsPgKVO4Wx1Cg1zaK6UBmN1MVjMHzBuHTA.png"
                alt="icon"
                onClick={() => handleImageClick(145)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/AumHgFmKxZ6LpHgzvK5gqbLZyvgeryiYiGp3uigq.png"
                alt="icon"
                onClick={() => handleImageClick(259)}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/1c09luDhnuarYPWmhNZGFLbKvMkksQblvHe5ekrM.png"
                alt="icon"
                onClick={() => handleImageClick(303)}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/6gjWT5M7ESdWCYcF1mF8zRxlffhin11stP3oZi2q.png"
                alt="icon"
                onClick={() => handleImageClick(198)}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/nLgQLeo2bdl3ZV33xKuWCq36m5IADrGXWjpnECcr.png"
                alt="icon"
                onClick={() => handleImageClick(108)}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/3zYqXEykzJtrTLVBtLVQqHx2NIoy7BgzguIWiGwa.png"
                alt="icon"
                onClick={() => handleImageClick(238)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/XTnBcYssqDBiw7SfzGUBYMWICxyo5nRbnEZ7Xq25.png"
                alt="icon"
                onClick={() => handleImageClick(5)}
              />
            </div>
          </div>


          <div
            className={[
              styles.homeAdditionalGamesTitle,
              viewBoxNumber != 2 ? styles.hideView : "",
            ].join(" ")}
          >
            <span>  
            </span>
            <div className={[styles.headinggames]}>Fishing 
            <div className={[styles.seeallbutton]}>
            {/* <div className={[styles.seealltext]}>See all     
            </div>
            <svg height="1em" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><path d="M16.88 15.53 7 5.66a1 1 0 0 0-1.41 1.41l9.06 9.06-8.8 8.8a1 1 0 0 0 0 1.41 1 1 0 0 0 1.42 0l9.61-9.61a.85.85 0 0 0 0-1.2Z" fill="#f2ca33" class="fill-231f20"></path><path d="m26.46 15.53-9.88-9.87a1 1 0 0 0-1.41 1.41l9.06 9.06-8.8 8.8a1 1 0 0 0 0 1.41 1 1 0 0 0 1.41 0l9.62-9.61a.85.85 0 0 0 0-1.2Z" fill="#f2ca33" class="fill-231f20"></path></g></svg> */}
            </div>
            </div>
          </div>
          <div
            className={[
              styles.homeAdditionalGamesView,
              viewBoxNumber != 2 ? styles.hideView : "",
            ].join(" ")}
          >
            <div>
            
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/0EKZ9RojHqDu3wBkUEZS9ijPOvjRoNJg60rXw6bz.png"
                alt="icon"
                onClick={() => handleImageClick(289)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/4TDn2z4twjrvDhPBulLETlCB2x0NOGfQ0YTN3k5S.png"
                alt="icon"
                onClick={() => handleImageClick(212)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/1d2HzD8qNtfHGo6WbuoFBzUJuZ4OEigW2twPip9n.png"
                alt="icon"
                onClick={() => handleImageClick(20)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/NMb6BIwOzFwCaVgdhCRpQPcR4MWvOkd9QIVvfeSR.png"
                alt="icon"
                onClick={() => handleImageClick(119)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/WXbKsTLPi9FLXRPEhh3PsSk0MpEZ94ePwc4aU4iX.png"
                alt="icon"
                onClick={() => handleImageClick(74)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/x7IvtI99oCUD6pKtQjXQu0cGZRhZLyPn56xgDskh.png"
                alt="icon"
                onClick={() => handleImageClick(71)}
              />
            </div>
          </div>


          <div
            className={[
              styles.homeAdditionalGamesTitle,
              viewBoxNumber != 3 ? styles.hideView : "",
            ].join(" ")}
          >
            <span>  
            </span>
            <div className={[styles.headinggames]}>Cards
            <div className={[styles.seeallbutton]}>
           {/* <div className={[styles.seealltext]}>See all     
            </div>
            <svg height="1em" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><path d="M16.88 15.53 7 5.66a1 1 0 0 0-1.41 1.41l9.06 9.06-8.8 8.8a1 1 0 0 0 0 1.41 1 1 0 0 0 1.42 0l9.61-9.61a.85.85 0 0 0 0-1.2Z" fill="#f2ca33" class="fill-231f20"></path><path d="m26.46 15.53-9.88-9.87a1 1 0 0 0-1.41 1.41l9.06 9.06-8.8 8.8a1 1 0 0 0 0 1.41 1 1 0 0 0 1.41 0l9.62-9.61a.85.85 0 0 0 0-1.2Z" fill="#f2ca33" class="fill-231f20"></path></g></svg> */}
            </div>
            </div>
          </div>

          <div
            className={[
              styles.homeAdditionalGamesView,
              viewBoxNumber != 3 ? styles.hideView : "",
            ].join(" ")}
          >
            <div>
            
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/QnQdA0mq5H1NG9JFvxSEyH1PCl9k3NdK9VcUfLGX.png"
                alt="icon"
                onClick={() => handleImageClick(211)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/uKU7o6jFKOu7JaXAtgH1kXtAp0Pkpg2cXTDK0OW0.png"
                alt="icon"
                onClick={() => handleImageClick(199)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/JDZAVn9fyULDVDaDBSc4aeEOVWEDGB5R3hc03KBR.png"
                alt="icon"
                onClick={() => handleImageClick(125)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/xMuyUS9shoFDKa2Cmf7OVS74VLQkHCzZBmBFCEQg.png"
                alt="icon"
                onClick={() => handleImageClick(152)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/4NsN5Z6Wtuj7WcqQk9dFVteH5tjN7jPjek3FDZAr.png"
                alt="icon"
                onClick={() => handleImageClick(113)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/UCr6At6iwEEqhzEw3F7UqYocmzMrrBkRXDMSxmOu.png"
                alt="icon"
                onClick={() => handleImageClick(114)}
              />
            </div>
          </div>
<div id="minigames" className={[styles.homeAdditionalGamesTitle]}>
          <span></span>
            Mini 
          </div>
          <div className={[styles.homeAdditionalGamesView]}>
            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhFMCWQasnpfqXPkwW91-8snV5cuyzaay9q3q_kaK_nR7wonU3JfIno5mgd-4h2mwKDH9dphi9kUtxrKpt9rGFOG-zrGqTQ8jc7pnCxOmmjfhXdm_yGiOrbAt5Pgh1DvhDqwR1IKAhJw1K1EEf5DAhKj5_peME8p5Nj_EkFH1aLjoGqhrKGowUKnXUcD0o/s1600/224.png"
                alt="icon"
                onClick={() => handleImageClick(224)}
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjK5rhHp1ykx6bsq7LTPzClgBtLLrXeveAVGr9-iT2W12NePqc0HT5-hP2cXt5onZ1sG58rnfz9hhAutG6qjkkbT9P7OZwlcJ0IhRQFGMPVR-NjUpDk4n0LX5zS00oz4VQYLzlyhe06KHMVxS0pjnbsdnHuWSqLeWeIrr_EAkJRcLRymsP5C2tXWrzcxRo/s1600/229.png"
                alt="icon"
                onClick={() => handleImageClick(229)}
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhON4fbjEiuOkYoKBpJtIaLeRU2vVIEierFp_VGldaHcecnDogL0m9G2x0WdxQxok7XtvrUMGaScflLo5Qxa82uvb0QS_TMberrK6Z_BbCfmnLvKOoXgkl7z78cb8kaq1zh1dui-CcjmyKDUwnCwDucY7zihe3hkxJWBJhoJ9v6mkqqMb4EMIKoiHSqKNw/s1600/236.png"
                alt="icon"
                onClick={() => handleImageClick(236)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/x4eZ5K4sTx6GzI7BLkg960h7RRq3gNjrO67eRvcy.png"
                alt="icon"
                onClick={() => handleImageClick(242)}
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhH0hlpK4zabi12fLWFTeAeBZ5HLfmPb4Vrodis6x0qV01GqSUnGt1HVYEWaQNzcpbNa8J9kOs79PDYBrDJ4HPt4gAgrDxjAqXHuUyd5Kx55crz_3Lm4hQE5XV4eTzjreNYT_sTBRGhlXBIGpWyzXz7IH8MeHxFtgAdizWSH5SVpf3EFs6zuY89dNqWT2Y/s1600/233.png"
                alt="icon"
                onClick={() => handleImageClick(233)}
              />
            </div>

            <div>
              <img
                src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/UkIkTcp8lU4UZuGKGrLTwke9j2xdYEzk8hYHr9hS.png"
                alt="icon"
                onClick={() => handleImageClick(241)}
              />
            </div>
          </div>
          {/* <button onClick={() => openGame("all3rdPartyGames")} className={[styles.buttonviewall]}><img className={[styles.imgviewall]}
src={require("../media/icons/viewall.png")}
alt="icon"
/>View All</button> */}

{/* <div id="slots" className={[styles.homeAdditionalGamesTitle]}>
          <span></span>
            <div className={[styles.headinggames]}>Slots
            <div className={[styles.seeallbutton]}>
            <div className={[styles.seealltext]}>See all     
            </div>
            <svg height="1em" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><path d="M16.88 15.53 7 5.66a1 1 0 0 0-1.41 1.41l9.06 9.06-8.8 8.8a1 1 0 0 0 0 1.41 1 1 0 0 0 1.42 0l9.61-9.61a.85.85 0 0 0 0-1.2Z" fill="#f2ca33" class="fill-231f20"></path><path d="m26.46 15.53-9.88-9.87a1 1 0 0 0-1.41 1.41l9.06 9.06-8.8 8.8a1 1 0 0 0 0 1.41 1 1 0 0 0 1.41 0l9.62-9.61a.85.85 0 0 0 0-1.2Z" fill="#f2ca33" class="fill-231f20"></path></g></svg>
            </div>
            </div>

          </div>
          <div className={[styles.homeAdditionalGamesView]}>
            <div>
              <img
                src={require("../media/icons/pggamessmlogo.png")}
                alt="icon"
              />
            </div>

            <div>
              <img
                src={require("../media/icons/jiligamessmlogo.png")}
                alt="icon"
              />
            </div>

            <div>
              <img
                src={require("../media/icons/fcgamessmlogo.png")}
                alt="icon"
              />
            </div>

            <div>
              <img
                src={require("../media/icons/jdbgamessmlogo.png")}
                alt="icon"
              />
            </div>

            <div>
              <img
                src={require("../media/icons/c9qgameslogo.png")}
                alt="icon"
              />
            </div>

            <div>
              <img
                src={require("../media/icons/spribegamessmlogo.png")}
                alt="icon"
              />
            </div>
          </div> */}
          {/* <button onClick={() => openGame("all3rdPartyGames")} className={[styles.buttonviewall]}><img className={[styles.imgviewall]}
src={require("../media/icons/viewall.png")}
alt="icon"
/>View All</button> */}

          <div className="min-h pd-15 mg-t-20 mg-b-10em">
            <span>Top Earner's List</span>

            <div className="col-view">{pageConst.winnersRecordList}</div>
          </div>
        </div>
        <ToastDialog
          intentData={toastDialogConst}
          updateState={updateToastDialogState}
        />
        <BottomNavbar activeBar="promotion" />
      </div>
    </div>
  );
}

export default Home;
